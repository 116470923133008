<template>
    <section class="section">
        <form @submit.prevent="search" class="row">
            <div class="col-4">
                <input v-model="condition.code" type="text" class="form-control" placeholder="ASIN・SKU">
            </div>
            <div class="col-4">
                <input v-model="condition.keyword" type="text" class="form-control" placeholder="商品名">
            </div>
            <div class="col-4">
                <form-select
                    v-model="condition.sync_status_group"
                    :options="options_sync_status_group"
                    empty_option = "--連携状態--"
                />
            </div>
            <div class="col-4">
                <form-select
                    v-model="condition.is_draft"
                    :options="options_is_draft"
                    empty_option = "-- 保留状態 --"
                />
            </div>
            <!--
            <div class="col-4">
                <select class="form-control">
                    <option value="">--カテゴリ--</option>
                    <option value=""></option>
                    <option value=""></option>
                    <option value=""></option>
                    <option value=""></option>
                </select>
            </div>
            -->
            <div class="col-4">
                <button type="submit" class="btn btn-primary btn-full">検索</button>
            </div>
            <div class="col-4">
                <template v-if="$store.getters['user/auth/canViewAllSku']()">
                <button type="button" @click="show_checkbox = !show_checkbox" class="btn btn-light btn-full" ><i class="bi bi-caret-down-fill"></i> 一括処理</button>
                </template>
            </div>
        </form>
        <transition name="fade">
            <div class="mt-3 pt-3 border-top" v-if="show_checkbox">
                <div class="row">
                    <div class="col-8">
                        <button @click="checkAll" type="button" class="btn btn-secondary">
                            <i class="bi bi-check-square" v-if="checked_all"></i>
                            <i class="bi bi-square" v-else></i>
                            全てチェック
                        </button>
                    </div>
                    <div class="col-8">
                        <button @click="bulkRelease" type="button" class="btn btn-warning btn-full" :disabled="checked_sku_ids.length === 0">チェックした {{ checked_sku_ids.length }} 件を保留解除</button>
                    </div>
                    <div class="col-8">
                        <button @click="searchRelease" type="button" class="btn btn-warning btn-full" :disabled="paginator_meta.total === 0">
                            <template v-if="paginator_meta.total <= maximum_limit">
                                表示中の {{ paginator_meta.total }} 件を全て保留解除
                            </template>
                            <template v-else>
                                表示中の {{ paginator_meta.total }} 件のうち {{ maximum_limit }} 件を保留解除
                            </template>
                        </button>
                    </div>
                </div>
                <div class="row mt-3">
                    <div class="col-8 offset-8">
                        <button @click="$refs.confirm_bulk_delete.show()" type="button" class="btn btn-danger btn-full" :disabled="checked_sku_ids.length === 0">チェックした {{ checked_sku_ids.length }} 件を削除</button>
                    </div>
                    <div class="col-8">
                        <button @click="$refs.confirm_search_delete.show()" type="button" class="btn btn-danger btn-full" :disabled="paginator_meta.total === 0">
                            <template v-if="paginator_meta.total <= maximum_limit">
                                表示中の {{ paginator_meta.total }} 件を全て削除
                            </template>
                            <template v-else>
                                表示中の {{ paginator_meta.total }} 件のうち {{ maximum_limit }} 件を削除
                            </template>
                        </button>
                    </div>
                </div>
                <div class="row mt-3" v-if="$store.getters['user/auth/canRetryError']()">
                    <div class="col-8 offset-8">
                        <button @click="bulkRetry" type="button" class="btn btn-outline-danger btn-full" :disabled="checked_sku_ids.length === 0">チェックした {{ checked_sku_ids.length }} 件を再試行</button>
                    </div>
                    <div class="col-8">
                        <button @click="searchRetry" type="button" class="btn btn-outline-danger btn-full" :disabled="paginator_meta.total === 0">
                            <template v-if="paginator_meta.total <= maximum_limit">
                                表示中の {{ paginator_meta.total }} 件を全て再試行
                            </template>
                            <template v-else>
                                表示中の {{ paginator_meta.total }} 件のうち {{ maximum_limit }} 件を再試行
                            </template>
                        </button>
                    </div>
                </div>
            </div>
        </transition>
    </section>

    <div class="alert alert-danger" v-if="hasError()">
        Amazon 連携エラーが発生しています。該当SKUを「再試行」してください。
    </div>

    <section class="section" v-for="sku,index of skus" :key="sku.sku_id" :class="{'deleting': sku.uneditable}">
        <transition name="fade">
            <div class="mb-1" v-if="show_checkbox">
                <label><input type="checkbox" v-model="checked_sku_ids" :value="sku.sku_id"> 選択</label>
            </div>
        </transition>
        <parts-inventory-item v-model="skus[index]" :is_watching="(condition.is_watching === 1)" :delay="index"></parts-inventory-item>
    </section>

    <paginator :meta="paginator_meta" v-if="paginator_meta" @move="search"></paginator>

    <confirm-dialog ref="confirm_bulk_delete" @ok="bulkDelete()">
        <p>削除してもよろしいですか？</p>
    </confirm-dialog>

    <confirm-dialog ref="confirm_search_delete" @ok="searchDelete()">
        <p>削除してもよろしいですか？</p>
    </confirm-dialog>
</template>


<script>
/**
 * TODO
 *
 * カテゴリで検索
 * 個別削除後の表示
 * レイジーロードの実装
 */
import Sku from '@/shared/models/entities/sku';
import Paginator from '@/shared/components/tool/Paginator';
import PartsInventoryItem from './parts/PartsInventoryItem';
import FormSelect from '@/shared/components/form/FormSelect.vue';
import ConfirmDialog from '@/shared/components/tool/ConfirmDialog.vue';

export default {
    name: 'PageInventory',
    components: {
        Paginator,
        PartsInventoryItem,
        FormSelect,
        ConfirmDialog,
    },
    inject: [
        'startScreenLoading',
        'endScreenLoading',
        'showMessage',
        'hasError',
    ],
    data() {
        return {
            condition: {
                is_watching: 1,
                sync_status_group: this.$route.query?.sync_status_group,
                is_draft: null,
                register_user_id: null,
                code: null,
                keyword: null,
                page: 1,
            },
            skus: [],
            paginator_meta: null,
            show_checkbox: false,
            /** 全てにチェック true/false */
            checked_all: false,
            checked_sku_ids: [],
            options_sync_status_group: [
                {'key': 100, 'label': '連携完了'},
                {'key': 200, 'label': '連携中'},
                {'key': 300, 'label': '連携エラー'},
            ],
            options_is_draft: [
                {'key': 0, 'label': '通常'},
                {'key': 1, 'label': '保留中'},
            ],
            /** 一括削除 or 一括保留解除の上限数 */
            maximum_limit: 500
        }
    },
    mounted() {
        this.search();
    },
    /*
    // これだと /inventory <-> /inventory/closed を検知してくれなかった
    beforeRouteUpdate(to, from, next) {
        if (to !== from) {
            this.search();
        }
        next();
    },
    */
    watch: {
        $route() {
            this.condition.sync_status_group = this.$route.query?.sync_status_group;
            this.condition.is_draft = null;
            this.condition.register_user_id = null;
            this.condition.code = null;
            this.condition.keyword = null;
            this.condition.page = 1;
            this.search();
        }
    },
    methods: {
        search(page = 1) {
            this.condition.register_user_id = this.$route.params.user;
            this.condition.is_watching = 'PageInventoryClosed' === this.$route.name ? 0 : 1;
            this.condition.page = page ?? 1;

            this.startScreenLoading();
            this.$http.get('/skus', {params: this.condition})
            .then(response => {
                this.skus = [];
                for (let row of response.data.data) {
                    this.skus.push(new Sku(row));
                }
                this.paginator_meta = response.data.meta;

                this.checked_all = false;
            })
            .finally(() => {
                this.endScreenLoading();
            });
        },
        // チェックした〇〇件を削除
        bulkDelete() {
            this.startScreenLoading();
            this.$http.post('/skus/bulk-delete', {sku_ids: this.checked_sku_ids})
            .then(() => {
                this.showMessage('削除しました');
                this.checked_sku_ids = [];  // 削除したのでチェックした配列は一旦空に
                this.search();
            })
            .finally(() => {
                this.endScreenLoading();
            });
        },
        // 表示中の〇〇件を全て削除
        searchDelete() {
            this.startScreenLoading();
            this.$http.post('/skus/search-delete', {
                condition: this.condition,
                count: this.paginator_meta.total
            })
            .then(() => {
                this.showMessage('削除しました');
                this.search();
            })
            .finally(() => {
                this.endScreenLoading();
            });
        },
        // チェックした〇〇件を保留解除
        bulkRelease() {
            this.startScreenLoading();
            this.$http.post('/skus/bulk-release', {sku_ids: this.checked_sku_ids})
            .then(() => {
                this.showMessage('保留解除しました');
                this.checked_sku_ids = [];  // 保留解除したのでチェックした配列は一旦空に
                this.search();
            })
            .finally(() => {
                this.endScreenLoading();
            });
        },
        // 表示中の〇〇件を全て保留解除
        searchRelease() {
            this.startScreenLoading();
            this.$http.post('/skus/search-release', {
                condition: this.condition,
                count: this.paginator_meta.total
            })
            .then(() => {
                this.showMessage('保留解除しました');
                this.search();
            })
            .finally(() => {
                this.endScreenLoading();
            });
        },
        // チェックした〇〇件を再試行
        bulkRetry() {
            this.startScreenLoading();
            this.$http.post('/skus/bulk-retry', {sku_ids: this.checked_sku_ids})
            .then(() => {
                this.showMessage('再試行を予約しました');
                this.checked_sku_ids = [];  // 再試行したのでチェックした配列は一旦空に
                this.search();
            })
            .finally(() => {
                this.endScreenLoading();
            });
        },
        // 表示中の〇〇件を全て再試行
        searchRetry() {
            this.startScreenLoading();
            this.$http.post('/skus/search-retry', {
                condition: this.condition,
                count: this.paginator_meta.total
            })
            .then(() => {
                this.showMessage('再試行を予約しました');
                this.search();
            })
            .finally(() => {
                this.endScreenLoading();
            });
        },
        // 全てをチェック(表示しているページのみ)
        checkAll() {
            this.checked_all = !this.checked_all;
            // ※これだと前や他のページでチェックした要素ががリセットされてしまう
            // this.checked_sku_ids = [];

            // ON [全てをチェック]
            if (this.checked_all) {
                this.skus.map((sku) => {
                    if (!this.checked_sku_ids.includes(sku.sku_id)) {
                        this.checked_sku_ids.push(sku.sku_id);
                    }
                });
            // OFF [全てをチェック]
            } else {
                this.skus.map((sku) => {
                    var index = this.checked_sku_ids.indexOf(sku.sku_id);
                    if (index !== -1) {
                        this.checked_sku_ids.splice(index, 1);
                    }
                });
            }
        },
    }
}
</script>

<style scoped>
.deleting {background: #DDDDDD;}
</style>
